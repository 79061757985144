
.eCommerce-container {
    height: 100%;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.shadow-box {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
    border-radius: 10px;
    background: #fff;
}
.data-content {
     margin: 20px;
     padding: 20px;
     .top-bar {
         width: 218px;
         height: 50px;
         background: url("../../../../assets/images/student/customer-info.png") no-repeat;
         .text {
             padding-left: 70px;
             line-height: 50px;
             color: #fff;
             font-size: 20px;
         }
     }
     .line-data {
         margin-top: 30px;
         .line-item {
             border: 1px solid #E5E5E5;
             margin-top: 10px;
             display: flex;
             align-items: center;
             text-align: center;
             font-size: 16px;
             .item-left {
                 width: 112px;
                 height: 118px;
                 background: #F8F8F8;
                 img {
                     margin-top: 24px;
                 }
                 .text {
                     line-height: 1;
                     margin-top: 5px;
                 }
             }
             .item-right {
                 width: 1%;
                 flex: 1;
                 display: flex;
                 .item {
                     width: 20%;
                     .text {
                         margin-top: 20px;
                         font-size: 18px;
                         line-height: 1;
                     }
                 }
             }
         }
     }
     .trend-wrapper {
         background: #fff;
         padding: 20px;
         .trend-category {
             display: flex;
             border: 1px solid #E5E5E5;
             .cate-left {
                 width: 120px;
                 background: #F8F8F8;
                 p {
                     line-height: 50px;
                     text-align: center;
                     font-size: 16px;
                     border-bottom: 1px solid #E5E5E5;
                     margin: 0;
                     height: 50px;
                     box-sizing: border-box;
                     &:last-child {
                         border-bottom: 0;
                     }
                 }
             }
             .cate-right {
                 width: 1%;
                 flex: 1;
                 .el-checkbox-group {
                     display: flex;
                     flex-wrap: wrap;
                     .el-checkbox {
                         margin-right: 0;
                         line-height: 50px;
                         margin-left: 48px;
                         width: calc(15% - 48px);
                         box-sizing: border-box;
                         &:nth-of-type(5), &:nth-of-type(13) {
                             width: calc(40% - 48px);
                         }
                         &:nth-of-type(8) {
                             width: calc(70% - 48px);
                         }
                         &:last-child {
                             width: calc(55% - 48px);
                         }
                         &:after {
                             content: '';
                             position: absolute;
                             left: -48px;
                             right: 0;
                             bottom: 0px;
                             height: 1px;
                             background: #E5E5E5;
                         }
                         &:nth-of-type(14), &:nth-of-type(15), &:nth-of-type(16), &:last-child {
                             &:after {
                                 content: none;
                             }
                         }
                     }
                 }
             }
         }
         .choose-number {
             margin-top: 30px;
             font-size: 18px;
             .number {
                 margin-left: 20px;
                 span {
                     color: #1AB163;
                 }
             }
         }
         .trend-content {
             margin-top: 30px;
             height: 300px;
             width: 100%;
         }
     }
 }
.sort-num {
    display: inline-block;
    background: #e7f6ef;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
}
.sort-img {
    display: inline-block;
    img {
        display: block;
    }
}
